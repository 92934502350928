import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <nav class="navbar sticky-top navbar-light bg-light">
    <div class="container p-0 p-md-1">
      <Link className="navbar-brand mt-md-3" to="/">
        <div class="text-gold">Church Street Launderette</div>
        <div class="text-dark cursive">Covering both domestic &amp; commercial customers</div>
      </Link>
      <div class="navbar-text float-sm-right">
        <div><i class="fa fa-phone"></i> <a class="ml-3 mr-2" href="tel:01388205919">01388 205919</a>/<a class="ml-2" href="tel:07732238973">07732 238973</a></div>
        <div><i class="fa fa-envelope"></i><a class="ml-3" href="mailto:info@cs-launderette.co.uk">info@cs-launderette.co.uk</a></div>
        <div class="hidden-sm-down"><i class="fa fa-pencil"></i><span class="ml-3">72 Church St, Shildon, DL4 1DY</span></div>
      </div>
    </div>
  </nav>
)

export default Header
