import React from "react"

const Footer = () => (
  <footer class="bg-space">
    <div class="container">
      <div class="row pt-5 pb-5">
        <div class="col-12 col-sm-4 mb-4">
          <div><strong>Find us on:</strong></div>
          <div>
            <a class="btn-round" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/churchstreetlaunderette72" title="Facebook" style={{padding: '8px 12px'}}><i class="fa fa-facebook fa-2x"></i></a>
            <a class="btn-round ml-3" target="_blank" rel="noopener noreferrer" href="https://www.yell.com/biz/church-street-launderette-shildon-7831144/" title="Yell" style={{padding: '8px 9px'}}><i class="fa fa-yahoo fa-2x"></i></a>
          </div>
        </div>
        <div class="col-12 col-sm-8">
          <div class="float-sm-right">
            <div><i class="fa fa-phone"></i> <a class="ml-3 mr-2" href="tel:01388205919">01388 205919</a>/<a class="ml-2" href="tel:07732238973">07732 238973</a></div>
            <div><i class="fa fa-envelope"></i><a class="ml-3" href="mailto:info@cs-launderette.co.uk">info@cs-launderette.co.uk</a></div>
            <div class="hidden-sm-down"><i class="fa fa-pencil"></i><span class="ml-3">72 Church St, Shildon, DL4 1DY</span></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="text-center bg-black">Church Street Launderette &copy; 2019</div>
  </footer>
)

export default Footer
